import React, { useState } from "react";
 
import { Link } from 'react-router-dom';

const Register = () => {
   // const history = useHistory();

    const [emptyForm, setEmptyForm] = useState(false);

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
    });

    const [userError, setUserError] = useState({
        nameError: null,
        emailError: null,
        passwordError: null,
    });

    // handle username
    const handleUsername = (e) => {
        setUser({ ...user, name: e.target.value });
        const regExp = /^[a-zA-Z0-9]{4,10}$/;
        if (regExp.test(e.target.value)) {
            setUserError({ ...userError, nameError: "" });
        } else {
            setUserError({ ...userError, nameError: "Enter a proper Name" });
        }
    };

    // handle Email
    const handleEmail = (e) => {
        setUser({ ...user, email: e.target.value });
        const regExp =
            /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
        if (regExp.test(e.target.value)) {
            setUserError({ ...userError, emailError: "" });
        } else {
            setUserError({
                ...userError,
                emailError: "Enter a proper Email",
            });
        }
    };

    // handle password
    const handlePassword = (e) => {
        setUser({ ...user, password: e.target.value });
        const regExp = /^[A-Za-z]\w{7,14}$/;
        if (regExp.test(e.target.value)) {
            setUserError({ ...userError, passwordError: "" });
        } else {
            setUserError({
                ...userError,
                passwordError: "Enter a proper Password",
            });
        }
    };

    // submitRegister
    const submitRegister = (e) => {
        e.preventDefault();
        if (user.name !== "" && user.email !== "" && user.password !== "") {
            // Handle user registration logic without Redux
            console.log("Registering user...", user);
            // Redirect to the desired page after successful registration
           // history.push("/users/login");
        } else {
            setEmptyForm(true);
        }
    };

    return (
        <React.Fragment>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-4 m-auto animated flipInY">
                        {emptyForm && (
                            <div className="alert alert-danger alert-dismissible animated zoomIn">
                                <button
                                    className="close"
                                    onClick={() => setEmptyForm(false)}
                                >
                                    <i className="fa fa-times-circle" />
                                </button>
                                <small className="font-weight-bold">
                                    Please fill in the details
                                </small>
                            </div>
                        )}
                        <div className="card">
                            <div className="card-header   text-white" style={{backgroundColor:"#ff4500"}}>
                                <p className="h4">Register Here</p>
                            </div>
                            <div className="card-body bg-light">
                                <form onSubmit={submitRegister}>
                                    <div className="form-group">
                                        <input
                                            name="name"
                                            value={user.name}
                                            onChange={handleUsername}
                                            type="text"
                                            className={`form-control ${
                                                userError.nameError
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            placeholder="Name"
                                        />
                                        {userError.nameError && (
                                            <small className="text-danger">
                                                {userError.nameError}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            name="email"
                                            value={user.email}
                                            onChange={handleEmail}
                                            type="email"
                                            className={`form-control ${
                                                userError.emailError
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            placeholder="Email"
                                        />
                                        {userError.emailError && (
                                            <small className="text-danger">
                                                {userError.emailError}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            name="password"
                                            value={user.password}
                                            onChange={handlePassword}
                                            type="password"
                                            className={`form-control ${
                                                userError.passwordError
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            placeholder="Password"
                                        />
                                        {userError.passwordError && (
                                            <small className="text-danger">
                                                {userError.passwordError}
                                            </small>
                                        )}
                                    </div>
                                    <div>
                                        <input
                                            type="submit"
                                            className="btn  text-white btn-sm"
                                            value="Register" style={{backgroundColor:"#ff4500"}}
                                        />
                                    </div>
                                </form>
                                <small>
                                    Have an Account ?
                                    <Link
                                        to="/login"
                                        className="text-primary"
                                    >
                                        {" "}
                                        Login
                                    </Link>
                                </small>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Register;
