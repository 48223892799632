import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import three from '../images/HomePage/7.jpeg';


function Landingone() {
  const slides = [
    { type: 'image', url: three, text: 'Text for Image 3', buttonText: 'Learn More' },

  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <button type="button" className="slick-prev">Previous</button>,
    nextArrow: <button type="button" className="slick-next">Next</button>,
  };

  return (
    <div className="video-slider mb-5" style={{background:"#1c1c1c"}}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <div className="image-slide text-center">
            <center> <img
                      src={slide.url}
                      alt={`Image ${index + 1} - ${slide.text}`}
                      style={{ maxWidth: "100%", height: "90vh" }}
                    />
              </center> 
              <div className="slide-text">
                {/*<h3>{slide.text}</h3>
                 <button>{slide.buttonText}</button> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Landingone;
