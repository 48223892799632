import React, { useState } from "react";  
import { Link, useHistory } from 'react-router-dom';
const Login = () => {
   // const history = useHistory();

    const [emptyForm, setEmptyForm] = useState(false);

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [userError, setUserError] = useState({
        emailError: null,
        passwordError: null
    });

    // handle Email
    const handleEmail = (e) => {
        setUser({ ...user, email: e.target.value });
        const regExp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
        if (regExp.test(e.target.value)) {
            setUserError({ ...userError, emailError: '' });
        } else {
            setUserError({ ...userError, emailError: 'Enter a proper Email' });
        }
    };

    // handle password
    const handlePassword = (e) => {
        setUser({ ...user, password: e.target.value });
        const regExp = /^[A-Za-z]\w{7,14}$/;
        if (regExp.test(e.target.value)) {
            setUserError({ ...userError, passwordError: '' });
        } else {
            setUserError({ ...userError, passwordError: 'Enter a proper Password' });
        }
    };

    // submitLogin
    const submitLogin = (e) => {
        e.preventDefault();
        if (user.email !== '' && user.password !== '') {
            // Handle login logic without Redux
            console.log('Logging in...', user);
            // Redirect to the desired page after successful login
          //  history.push('/dashboard');
        } else {
            setEmptyForm(true);
        }
    };

    return (
        <React.Fragment>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-4 m-auto animated flipInY">
                        {emptyForm && (
                            <div className="alert alert-danger alert-dismissible animated zoomIn">
                                <button className="close" onClick={() => setEmptyForm(false)}>
                                    <i className="fa fa-times-circle"/>
                                </button>
                                <small className="font-weight-bold">Please fill in the details</small>
                            </div>
                        )}
                        <div className="card">
                            <div className="card-header  text-white" style={{backgroundColor:"#ff4500"}}>
                                <p className="h4">Login Here</p>
                            </div>
                            <div className="card-body bg-light">
                                <form onSubmit={submitLogin}>
                                    <div className="form-group">
                                        <input
                                            name="email"
                                            value={user.email}
                                            onChange={handleEmail}
                                            type="email"
                                            className={`form-control ${userError.emailError ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                        />
                                        {userError.emailError && <small className="text-danger">{userError.emailError}</small>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            name="password"
                                            value={user.password}
                                            onChange={handlePassword}
                                            type="password"
                                            className={`form-control ${userError.passwordError ? 'is-invalid' : ''}`}
                                            placeholder="Password"
                                        />
                                        {userError.passwordError && <small className="text-danger">{userError.passwordError}</small>}
                                    </div>
                                    <div>
                                        <input type="submit" className="btn  text-white btn-sm" value="Login" style={{backgroundColor:"#ff4500"}}/>
                                    </div>
                                </form>
                                <small>
                                    Don't have an Account ?
                                    <Link to='/register' className="text-Primary"> Register</Link>
                                </small>
                            </div>
                            <div className="card-footer text-center">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
