import React, { useState, useEffect } from 'react';

function StaticGallery() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const importedImages = [];
      for (let i = 1; i <= 3; i++) {
        const image = await import(`../images/gallery/${i}.jpg`);
        importedImages.push(image.default);
      }
      setImages(importedImages);
    };

    importImages();
  }, []);

  return (
    <div>
      <div className="row">
        {images.map((image, index) => (
          <div key={index} className="col-lg-4 col-md-12 mb-4 mb-lg-0">
           <a href={image} data-toggle="lightbox" target='_blank'>
	
            <img
              src={image}
              className="w-100 shadow-1-strong rounded mb-4 card"
             style={{maxHeight:'50vh'}}
            />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
export default StaticGallery