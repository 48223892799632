import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

//import Aboutus from './Pages/Aboutus';
import Home from './Pages/Home'; 
import Header from './Components/Header';
import Aboutus from './Pages/Aboutus';
import ContactPage from './Components/ContactPage';
import Gallery from './Components/StaticGallery';
import Login from './Components/users/login/Login';
import Register from "./Components/users/register/Register";
import Events from './Pages/Events';
//import Gallery from './Components/Gallery';

function App() {
  // const images = [
  //   { src: 'https://placekitten.com/800/600', caption: 'Image 1' },
  //   { src: 'https://placekitten.com/800/601', caption: 'Image 2' },
  //   { src: 'https://placekitten.com/800/602', caption: 'Image 3' },
  //   // Add more images as needed
  // ];
  return (
    <div className="App">
      <Router>
      <div>
        <Header/>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<Aboutus />} />
          <Route path="/contact" exact element={<ContactPage />} />
          <Route path="/career" exact element={<ContactPage />} />
          <Route path="/Gallery" exact element={<Gallery />} />
          <Route path="/Events" exact element={<Events/>}/>
          <Route  exact  path="/login" element={<Login/>}/>
         <Route exact path="/register" element={<Register/>}/>
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
}

export default App;
