import React from 'react'

function Footer() {
  return (
 <div class="container-wraper ">
 
      <footer class="text-center text-lg-start mt-3" style={{backgroundColor: "#db6930;"}}>
        <div class="container d-flex justify-content-center py-3">
            <a href='https://www.facebook.com/srimantrapeeteshwaripeetam' target='_blank'>
          <button type="button" class="btn btn-primary btn-lg btn-floating mx-2" >
            <i class="fa fa-facebook-f"></i>
          </button>
          </a>
          <a href='https://www.youtube.com/@srimantrapeeteshwari' target='_blank'>
            <button type="button" class="btn btn-primary btn-lg btn-floating mx-2" >
            <i class="fa fa-youtube"></i>
          </button>
          </a>
          <a href='https://www.instagram.com/srimantrapeeteshwaripeetam/' target='_blank'>
          <button type="button" class="btn btn-primary btn-lg btn-floating mx-2" >
            <i class="fa fa-instagram"></i>
          </button>
          </a>
        
        </div>
     
         <div class="text-center text-white p-3" style={{backgroundColor: "#db6930;"}}>
          © 2024 Copyright:
          <p class="text-white" >Shri Mantrapeeteshwari Peetam Trust</p>
        </div>  
      </footer>
      
    </div> 
  )
}

export default Footer