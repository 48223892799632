import React from 'react'
import Landingone from '../Components/Landingone'
import LandTwo from '../Components/LandTwo'
import ContactPage from '../Components/ContactPage'

function Home() {
  return (
     <React.Fragment>
        <Landingone/>
      <LandTwo/>
      <ContactPage/>
     </React.Fragment>
    
  )
}

export default Home