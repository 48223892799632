import React from 'react'
import event from '../images/event.jpg';

function Events() {
  return (
    <div>
<img src={event}  style={{ maxWidth: "100%", height: "90vh" }}/>


    </div>
  )
}

export default Events