// ContactPage.js
import React from 'react';
import '../css/ContactPage.css'

const ContactPage = () => {
    const whatsappNumber = '+918748906999'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hi.');
  
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;
  
  return (
    
    <div className="container mt-3 mb-3" >
        <div className='row'>
      <h2 className='heading uppercase'>Shri Mantrapeeteshwari Peetam Trust (R)</h2>
      
      <div className='col-lg-4 text-lg-start'>      
             <p>Call for the Appointment!!</p>
            <div>
                <strong>Hours</strong>  9:00 AM to 8:00 PM
            </div>
            <div className="contact-info">
                <div className='whatsapp'>
                    <a   href="https://wa.me/918748906999" target="_blank" rel="noopener noreferrer" className='btn btn-md btn-outline-success mr-10px'>
                    <i class="fa fa-whatsapp"></i>WhatsApp 
                    </a>
                    <a   href="tel:918748906999" target="_blank" rel="noopener noreferrer" className='btn btn-outline-secondary ml-2'>
                    <i class="fa fa-phone"></i> +91 8748906999  
                    </a>
                     <a href="mailto:shrimantrapeeteshwari@gmail.com"><i class="fa-envelope"></i>shrimantrapeeteshwari@gmail.com</a>
                </div>
            </div>
              
            
            <div className='Address '>
                <p className='mb-0'>
                Shri Mantrapeeteshwari Peetam Trust (R) </p>
                <p className='mb-0'>No 17, Hesarghatta Village,</p>
                   <p className='mb-0'>  Hesaraghatta , Behind Government School </p>
                   <p className='mb-0'> Bengaluru - 560088</p>
                   <p className='mb-0'>Karnataka, India</p>
                   {/* <p className='mt-0'>560088</p> */}
            </div>
        </div>
        <div className='col-lg-8'>
        <div  >
          
        <iframe  style={{width:"100%",height:"300px"}}  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3885.4109352858036!2d77.4738892750793!3d13.13645928719425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA4JzExLjMiTiA3N8KwMjgnMzUuMyJF!5e0!3m2!1sen!2sin!4v1703767932420!5m2!1sen!2sin" ></iframe>
            </div>
      </div>
        
    </div>
    </div>
  );
};

export default ContactPage;
