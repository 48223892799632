// Header.js
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../images/logo.jpeg'

//import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <React.Fragment>
    



<nav class="navbar navbar-expand-lg navbar-light  " style={{backgroundColor:"#ff4500"}}>
   <Link  to="/" class="navbar-brand text-white" >
      <img src={logo} style={{maxHeight:'50px'}} className='rounded-circle'/>
      Shri Mantrapeeteshwari Peetam     </Link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <Link to="/"className='text-white  nav-link'>Home</Link>
        </li>
        <li class="nav-item">
        <Link to="/about" className='text-white nav-link'>About Us</Link>
          
        </li>
       
        <li class="nav-item">
        <Link to="/Gallery" className='text-white nav-link'>Gallery</Link>
        </li>
        <li class="nav-item">
        <Link to="/Gallery" className='text-white nav-link'>Video</Link>
        </li>
        <li class="nav-item">
        <Link to="/Events" className='text-white nav-link'>Events</Link>
        </li>
        <li class="nav-item">
        <Link to="/contact" className='text-white nav-link'>Career</Link>
        </li>
        <li class="nav-item">
        <Link to="/contact" className='text-white nav-link'>Contact</Link>
        </li>
      </ul>
 
    
    <ul className="navbar-nav ml-auto">
    <React.Fragment>
            <li className="nav-item">
                <Link to='/login' className='text-white nav-link'>Login</Link>
            </li>
            <li className="nav-item">
                <Link to='/register' className='text-white nav-link'>Register</Link>
            </li>
        </React.Fragment>

                            </ul>
  </div>
</nav>
    </React.Fragment>
  );
};

export default Header;

