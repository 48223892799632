import React from 'react'
import '../css/about.css'
import mession from '../images/Mission.webp';
import logo from '../images/logo.jpeg'

function LandTwo() {
    const containerStyle = {
        width: '100%',
        // Add other styles as needed
      };
  return (
   <div  className='container about'>
    <div className='Container'>
        <h4 className='title mt-2  text-lg-start'  > Shri Mantrapeeteshwari Peetam Trust</h4>
        <div className='row d-flex justify-content-center'>
            <div className='col-md-4 col-sm-12 p-lg-2 mb-1' >
                <div className='card border-0 shadow '>
                    <div className='card-header border-0'>
                        <img style={containerStyle} src={logo}/>
                    </div>
                    <div className='card-body border-0'>
                        <h5>Our Values</h5>
                        <p>Our organization is built on a foundation of compassion, integrity, and accountability. We believe in treating everyone with dignity and respect, and we are committed to using our resources in the most effective and efficient way possible.</p>
                    </div>
                </div>
            </div>
            
            <div className='col-md-4 col-sm-12  p-lg-2 mb-1'>
                <div className='card border-0 shadow'>
                    <div className='card-header border-0'>
                        <img style={containerStyle} src={mession} className='rounded-circle'/>
                    </div>
                    <div className='card-body border-0'>
                        <h5>Our Mission</h5>
                        <p>At Shri Mantrapeeteshwari Peetam, our mission is to improve the lives of those in need by providing essential services and support to our communities. We believe that everyone deserves access to basic necessities such as food, shelter, and healthcare.</p>
                    </div>
                </div>
            </div>

            {/* <div className='col-md-4 col-sm-12  p-lg-2 mb-1 '>
                <div className='card border-0 shadow'>
                    <div className='card-header border-0'>
                        <img style={containerStyle} className='rounded-circle' src='https://img1.wsimg.com/isteam/ip/77d9cd61-a891-454d-8af0-3cd3f37a26d4/ASHRAM124.jpg/:/cr=t:0%25,l:21.88%25,w:56.25%25,h:100%25/rs=w:730,h:730,cg:true'/>
                    </div>
                    <div className='card-body border-0'>
                        <h5>Our Team</h5>
                        <p>Our team is made up of dedicated professionals and volunteers who are passionate about making a difference in our communities. We work tirelessly to ensure that our programmes and services are meeting the needs of those we serve.</p>
                    </div>
                </div>
            </div> */}
        </div>
    </div>

   </div>
  )
}

export default LandTwo