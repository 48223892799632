import React from "react";

function Aboutus() {
  return (
    <React.Fragment>
      <section className="jumbotron" style={{ backgroundColor: "whitesmoke" }}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src="https://img1.wsimg.com/isteam/ip/77d9cd61-a891-454d-8af0-3cd3f37a26d4/WhatsApp%20Image%202023-07-17%20at%203.35.37%20PM.jpeg/:/rs=w:374,h:500,cg:true,m/cr=w:374,h:500" />
          </div>
          <div className="col-md-6 col-sm-12 align-middle px-5 py-5 mt-5">
            <h4 className="heading">
              Welcome to Shri Mantrapeeteshwari Peetam Trust.
            </h4>
           
            <p> 
              Shri Swamigal's aims is to engage the mankind in Sanatana Dharma
              Margam and to elevate them to include the them in vedic and
              vaidika margam.
            </p>
            <p>
              Shri Swamigal's Shri Mantrapeeteshwari Peetam , is an important,
              spiritual center for Sadhana dedicated to vedic dharma and
              Shanmatha Upasana school for thought .
            </p>
          </div>
        </div>
      </section>

      <div className="mt-3">
        <h4 className="heading">
          About Shri Shri Mantrapeeteshwari Peetam  -
        </h4>
        <div className="container  text-lg-start text-justify">
        <p>
          Shri Swamigal Born on January 17 1984 , in the month of Pushya , in
          Arudra Nakshtra,in Bhaskara kshetram, Kumbakonam by the grace of
          Mantra Peeteshwari Mangala Nayaki to blessed parents Smt.Rama and Raja
          Raman Son (BALAJI). It is noteworthy that Sri Swamigal comes in the
          lineage of Periyavachan Pillai Dynasty.
        </p>
        <p>
          Since his chid hood , he was very intrested in sprituality, so his
          parents did his brahmopadesham at the age 7 and sent him Patashala, He
          studied Samaveda for 8 years at the place of shri Raja Veda Kavya
          Patashala, Kumbakonam.
        </p>

        <p>
          After that , he stayed there for more days and visited his favaroite
          dynasty Shri Mantra Peetershwari Sametha , Shri Adi Kumbeshwara Swami
          -Every day without fail.
        </p>
        <p>
          In the year 1999, By the grace of Jagadguru Shri Jayendra Saraswathi
          Shankaracharya Swamigal,Shri Knachi Kamakoti Peetam, Shri
          Prakashanandanatha the primary disciple of Shri Purna Nanda Mandali,
          Madrapuri graced Shri Swamigal with shri Vidya Mantra Upadesha .
        </p>
        <p>
          At the time shri Jayendra saraswathi swamigal also Blessed by giving a
          divya Mangala Vigraham of sarva mantra swarupini shri Managala
          Narayani. From that day Trikal Pooja's are being performed every day.
          As per the Guidence of Shri Jayendra saraswathi Swamigal stayed and
          served at vellore Shri Narayani Peetam for some time After that he
          went on Yatra to Rishikesh, Tiruvannamalai, Chidambaram and many other
          Holy places and continued his regular pooja and Veda Parayanam.
        </p>
        <p>
          In the year of 2004 , again by the grace of Shri Jayendra saraswathi
          swamigal , shri swamigal stayed at Kalavai, Shri Matam Adishtanam for
          4 years and performed pooja's, Durga Sapthashati Parayanam and Shri
          Vidya aradhana .
        </p>
        <p>
          During the Shri Shankara Jayanti Utsava in 2008 , Shri Jayendra
          Saraswathi swamigal Dharma puram Adhinam 26th Gurumaha Sannidham ,
          Shri shanmuga desiga Gnana sambanda Paramacharya swamigal , in the
          auspicious presence of Shri Vidyagurunathar , Shri Prakshananda Nathar
          intiated Shri Swamigal in to Monastic deeksha was Given the name of
          Shri Sadhanandendra Saraswathi and was Blessed with a Name as Shri
          Mantra peeteshwari Peetam .
        </p>
        <p>
          And when shri shri Jayendra Saraswathi Swamigal gave his Blessings ,
          He Appreciated that this Shri Mantra Peeteshwari Peetam would become
          an authority of all Mantra shastras.
        </p>
        <p>
          Along with that ,Shri Jayendra Swamigal further blessed by giving a
          Shri Chakram . As per the Krama of Shri Adi Shankara Bhagwadpada, Shri
          Swamigal aslo blessed with Ganapati, surya , Eshwara, Vishnu,
          Subramanya Murthies , Shri Adi Shankara Bhagwadpada Paduka.
        </p>

        <p>
          After that Shri Sadhanandendra Saraswathi Swamigal went on Kshetra
          yatra for some time and in the year 2015, with supoort of Radha
          Krishna Goshala Trust, he Established Shri Mantra Peeteshwari Peetam
          at Vyasarah Katta alias Hesaraghatta in Bengalore , Karnataka. ( the
          place where Vyasa Maharshi did his Penance) Dharma Karyas and Pooja's
          of Shri Mangala Narayani Devi, as Sarva Mantra Swarupini and Shri
          Vidya Pooja's are being performed at the Peetam.
        </p>
        <p>
          Parameshti Guru of Shri Swamigal, Shri Anandanandanathar Painganadu
          Shri sundara shastri was renowned Shri Vidya Upasaka and he gave shri
          vidya mantra Deeksha to Shri kanchi Mahaswamigal.It is noteworthy that
          Shri Mahaswamy found Addressed him as "Mantra Murthy".
        </p>
        </div>
       
        <p>
          <u className="fw-600">The Shri Vidya Lineage of Shri Mantra Peeteshwari Peetam</u>
        </p>
        <p> Vimarsanandanathar (Karuveli shri Venkatrama Shastris) |</p>

        <p>Pratipanandanatha (Shri Swaminatah Shastris)|</p>

        <p>
          Anandanandanathar(Manthramurthy Painganadu Srhi sundara shastris)|
        </p>

        <p>Purnanandanatha( Melpadi shri Krishna murthy)|</p>

        <p>Prakashanandanathar(Papanasham Balasubramanyam)|</p>

        <p>Shri Sadhanandendra Saraswathi</p>
        <div className="container  text-lg-start text-justify">
        <p className="mt-3">
          Now shri Pranavananda Saraswathi Avadootha Mahaswamy of pudukottai
          shri Bhuvaneshwari Peetam is giving his guidance and blessings to Shri
          Mantra peeteshwari Peetam . All the 4 Navaratri Utsavas are
          celabreated elaborately every year at Shri Mantra peeteshwari Peetam ,
          Shri Vidya poojas and Shri Vidya Homanas are performed in detail
          manner. Also Shri Swamigal is keen to maintain the Anceint temples in
          India and continue the regular pooja’s and utsavam there.
        </p>
        <p>
          He is also intense at propagating in performing Veda parayanam , Shri
          Durga Saptashati Parayanam, Vedic Kriyas and Goshala Samrakshna (Gou
          Samrakshna) . Shri Swamigal is also making efforts to proagate &
          protect Vedas , Tirumurai Divya Prabandham and Puranas.
        </p>
      </div>
      </div>
    </React.Fragment>
  );
}

export default Aboutus;
